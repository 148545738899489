@font-face {
    src: url("../fonts/glyphicons-halflings-regular.eot")
}

@font-face {
    src: url("../fonts/glyphicons-halflings-regular.svg")
}

@font-face {
    src: url("../fonts/glyphicons-halflings-regular.ttf")
}

@font-face {
    src: url("../fonts/glyphicons-halflings-regular.woff")
}

@font-face {
    font-family: OpenSans-Bold;
    src: url("../fonts/OpenSans-Bold.eot")
}

@font-face {
    font-family: OpenSans-Regular;
    src: url("../fonts/OpenSans-Regular.eot")
}

@font-face {
    font-family: OpenSans-Light;
    src: url("../fonts/OpenSans-Light.eot")
}

@font-face {
    font-family: OpenSans-LightItalic;
    src: url("../fonts/OpenSans-LightItalic.eot")
}

@font-face {
    font-family: OpenSans-Italic;
    src: url("../fonts/OpenSans-Italic.eot")
}

@font-face {
    font-family: OpenSans-Bold;
    src: url("../fonts/OpenSans-Bold.ttf")
}

@font-face {
    font-family: OpenSans-Regular;
    src: url("../fonts/OpenSans-Regular.ttf")
}

@font-face {
    font-family: OpenSans-Light;
    src: url("../fonts/OpenSans-Light.ttf")
}

@font-face {
    font-family: OpenSans-LightItalic;
    src: url("../fonts/OpenSans-LightItalic.ttf")
}

@font-face {
    font-family: OpenSans-Italic;
    src: url("../fonts/OpenSans-Italic.ttf")
}

body {
    font-family: OpenSans-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input::-webkit-calendar-picker-indicator{
    display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
	display: none; 
    visibility: hidden !important;
}

.logo-portail {
    margin: 20px;
}

#list-situation {
    width: 100%;
}

#list-situation li {
    margin: 0;
    padding-top: 15px;
}

#list-situation h3 {
    display: inline-block;
    margin: 0 0 10px;
    vertical-align: top;
}

#list-situation img {
    display: inline-block;
    width: 32px;
    height: 32px;
}

#cond-vente {
    float: right;
}

.required:after {
    content: "*";
}

#footer a:hover {
    color: #fff;
}

#footer .modal a:hover {
    color: #000;
}

.footernomsociete {
    padding-top: 10px;
}

.footerrelationclient {
    margin-top: 10px;
    color: #CCC
}

.footerrelationclient {
}

.logo {
    min-height: 180px;
    text-align: right;
    line-height: 180px;
}

.lien-station {
    text-align: right;
}

.logo-portail {
    margin-right: 60px !important;
    margin-top: -20px !important;
}

.pic img {
    width: 100%;
    height: auto
}

.contact {
    margin: -40px 40px 40px 0;
    color: #000;
}

.bouton {
    padding: 2px 5px 2px 5px;
    line-height: 22px;
    color: #fff;
    border: 0px;
    display: inline-block;
    margin: 3px;
}

.boutonnoir {
    background-color: #000 !important
}

.bouton:hover, .bouton:focus, .bouton:active {
    background-color: #000;
    color: #fff;
    text-decoration: none;
}

.btn-color:hover, .btn-color:focus, .btn-color:active {
    background-color: #000;
    border-color: #000;
    color: #fff;
    text-decoration: none;
}

.contenu {
    padding-left: 10px;
    min-height: 300px;
}

.small {
    font-size: 9px;
}

.datetitre {
    display: inline-block;
}

.back {
    height: 32px;
    width: 32px;
    float: right;
    background: url(../images/back.png) no-repeat;
    border-radius: 16px;
    margin-top: 10px;
}

.back:hover {
    background-color: #000
}

.list-group-item-info {
    color: #31708f;
    background-color: #fff;
    border-color: #fff;
}

.form-control {
    color: #000;
    padding: 4px 8px;
}

.input-group .form-control {
    z-index: 0;
}

h1 {
    font-size: 50px;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: -30px;
}

h2 {
    color: #000;
    font-size: 24px;
    border-bottom: 1px solid #000;
    line-height: 50px;
}

img.logo-portail {
    max-height: 250px;
    max-width: 250px;
    margin: 0px auto;
}

#liste-station {
    height: 300px;
    margin-top: 100px;
    /*padding-bottom: 100px;*/
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#list-situation li {
    margin: 20px;
}

#flags {
    z-index: 500
}

#frflag {
    padding: 8px;
    display: block;
    float: right;
}

#frflag a {
    color: #fff;
}

#frflag a:link {
    color: #fff;
    font-family: OpenSans-Bold;
    padding: 0 5px 0 5px;
    background: rgba(255, 255, 255, 0);
}

#frflag a:hover {
    background: rgba(255, 255, 255, 0.3);
    text-decoration: none;
}

#frflag a.current {
    color: #fff;
    font-family: OpenSans-Bold;
    padding: 0 5px 0 5px;
    background: rgba(255, 255, 255, 0.3);
}

#enflag {
    padding: 8px;
    display: block;
    float: right;
}

#enflag a {
    color: #fff;
}

#enflag a:link {
    color: #fff;
    font-family: OpenSans-Bold;
    padding: 0 5px 0 5px;
    background: rgba(255, 255, 255, 0);
}

#enflag a:hover {
    background: rgba(255, 255, 255, 0.3);
    text-decoration: none;
}

#enflag a.current {
    color: #fff;
    font-family: OpenSans-Bold;
    padding: 0 5px 0 5px;
    background: rgba(255, 255, 255, 0.3);
}

#enflag, #frflag,  .bouton, .back{
    background-color: #2f3232;
    color:#fff;
}

.container {
    margin-bottom: 100px;
}

.page-header {
    padding-bottom: 0;
    margin: 0;
    border-bottom: none;
}

table {
    max-width: 550px;
    margin-bottom: 50px;
}

th {
    font-weight: normal;
    font-size: 20px;
    border-bottom: 1px solid #000;
    height: 80px;
}

td {
    border-bottom: 1px solid #cecece;
    height: 60px;
    min-width: 100px;
}

.datepicker td {
    min-width: inherit;
}

.pic {
    min-height: 500px;
    text-align: right;
    background: url(../images/chute.jpg) no-repeat;
    background-size: 100% auto;
}

input[type="file"] {
    width: 168%;
}

.contact-mobile {
       display: none; 
    }


@media (max-width: 767px) {
    .logo {
        min-height: 80px;
        text-align: center;
    }

    .logo-portail {
        margin-right: 0px !important;
        margin-top: -20px !important;
    }

    h1 {
        margin: 20px 0 20px 0;
        font-size: 36px;
        text-align: center
    }

    .contenu {
        padding-left: 0px;
    }

    .contact-mobile {
       display: block; 
       text-align: right; 
    }


    .pic {
        display: none; 
    }

    .contact {
       display: none; 
    }

    .container {
        margin-bottom: 0 !important;
    }

    .pic {
        min-height: 200px;
        height: 200px;
        background-position: bottom
    }

    #footer {
        position: relative;
        height: inherit;
        min-height: 80px;
        background-color: #000;
    }

    input[type="file"] {
        width: 100%;
    }
}

#footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
    height: 60px;
    background-color: #000;
}

[data-toggle="popover"]{
    cursor: pointer;
}

.sous-titre {
    font-size: 24px;
    padding-left: 10px;
    border: none;
    line-height: inherit;
}

.mention-title {
    font-weight: bold;
}
